<template>
  <v-card width="100%">
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
      >
        <v-toolbar-title
          data-cy="np_info_title"
        >
          <router-link
            :to="{ name: 'NaturalPerson' }"
            class="text-decoration-none"
            style="color: inherit"
          >
            {{ $t('person.listing.title') }}
          </router-link>
          <span
            v-if="person"
            class="font-weight-medium grey--text text-body-1"
          >
            <v-icon>mdi-chevron-right</v-icon>
            <strong>{{ person | natural_person_name(1) }}</strong>&nbsp;
            <small>{{ person | natural_person_name(2) }}</small>
          </span>
        </v-toolbar-title>

        <v-spacer />
        <v-btn
          text
          @click.prevent="$refs.person.onComplete()"
          v-if="person_id"
          data-cy="save_np_btn"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          {{ $t('person.details.buttons.save') }}
        </v-btn>
        <PopupDelete
          v-if="person_id"
          v-slot:default="{ on, loading }"
          @yes="(done) => {
            $store.dispatch('natural_person/remove', person_id)
              .then(() => {
                this.$router.replace({ name: 'NaturalPerson' })
              })
              .catch((error) => { console.warn(error) })
              .finally(done); // notify popup stop loading and close
          }"
          data-cy="natural_person"
        >
          <v-btn
            text
            color="error"
            v-on="on"
            :loading="loading"
            data-cy="delete_np_btn"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            {{ $t('person.details.buttons.delete') }}
          </v-btn>
        </PopupDelete>
      </v-app-bar>
    </v-card-title>
    <v-container
      fill-height
      fluid
    >
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <Person2
        ref="person"
        :person-id="person_id"
        @onLoad="(person) => {
          this.person = person;
        }"
        @switchToID="onSwitchToID"
        @onSave="onSave"
      />
    </v-container>
  </v-card>
</template>

<script>
import * as helper from '@/store/helper'
import Person2 from '@/components/forms/Person2'
import PopupDelete from '@/components/popups/PopupDeleteConfirmation'

export default {
  components: {
    Person2,
    PopupDelete
  },
  watch: {
    $route (to, unusedFrom) {
      if (to.name === 'NaturalPersonEdit') {
        this.person_id = to.params.np_id
      } else if (to.name === 'NaturalPersonCreate') {
        this.person_id = 0
      }
    }
  },
  data () {
    return {
      // columns: ['role', 'name', 'action'],
      // options: {
      // 	filterable: true,
      // },
      person_id: 0,
      requestLoading: false,
      form2: helper.initialPerson(),
      person: null
    }
  },
  methods: {
    ...helper,
    onSave (saved_person) {
      // var person = JSON.parse(JSON.stringify(this.form2));

      // // console.log('onSave', this, person)
      if (!this.person_id) {
        return this.$router.replace({
          name: 'NaturalPersonEdit',
          params: {
            np_id: saved_person.id
          }
        })
      }

      this.form = saved_person
      this.person_id = saved_person.id
    },
    onSwitchToID (person_id) {
      // this.person_id = person_id;
      this.$router.push({
        name: 'NaturalPersonEdit',
        params: {
          np_id: person_id
        }
      })
    }
  },
  created () {
    // console.log('CustomerInfor', this)
    if (this.$route.params.np_id) {
      this.person_id = this.$route.params.np_id
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
/* button.btn-sm.btn-block {
	width: 65px;
	float: left;
	margin: 0 5px;
}
.v--modal-overlay {
	z-index: 9999;
} */
/* .box.box-primary {
	max-height: calc(100vh - 51px - 149px);
    overflow-y: auto;
    overflow-x: hidden;
} */
/* .btn-primary {
	margin: auto 3px;
} */
/* @media (max-width: 767px) {
	.box.box-primary {
		max-height: calc(100vh - 51px - 149px - 50px);
	}
} */
.wizard-header {
	display: none;
}
</style>
