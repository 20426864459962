var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"100%"}},[_c('v-card-title',[_c('v-app-bar',{staticClass:"white pa-0",attrs:{"flat":"","elevate-on-scroll":"","dense":""}},[_c('v-toolbar-title',{attrs:{"data-cy":"np_info_title"}},[_c('router-link',{staticClass:"text-decoration-none",staticStyle:{"color":"inherit"},attrs:{"to":{ name: 'NaturalPerson' }}},[_vm._v(" "+_vm._s(_vm.$t('person.listing.title'))+" ")]),(_vm.person)?_c('span',{staticClass:"font-weight-medium grey--text text-body-1"},[_c('v-icon',[_vm._v("mdi-chevron-right")]),_c('strong',[_vm._v(_vm._s(_vm._f("natural_person_name")(_vm.person,1)))]),_vm._v("  "),_c('small',[_vm._v(_vm._s(_vm._f("natural_person_name")(_vm.person,2)))])],1):_vm._e()],1),_c('v-spacer'),(_vm.person_id)?_c('v-btn',{attrs:{"text":"","data-cy":"save_np_btn"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.person.onComplete()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" "+_vm._s(_vm.$t('person.details.buttons.save'))+" ")],1):_vm._e(),(_vm.person_id)?_c('PopupDelete',{attrs:{"data-cy":"natural_person"},on:{"yes":function (done) {
          _vm.$store.dispatch('natural_person/remove', _vm.person_id)
            .then(function () {
              this$1.$router.replace({ name: 'NaturalPerson' })
            })
            .catch(function (error) { _vm.console.warn(error) })
            .finally(done); // notify popup stop loading and close
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var on = ref.on;
        var loading = ref.loading;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"error","loading":loading,"data-cy":"delete_np_btn"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" "+_vm._s(_vm.$t('person.details.buttons.delete'))+" ")],1)]}}],null,false,3558934793)}):_vm._e()],1)],1),_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('loading',{attrs:{"active":_vm.requestLoading,"is-full-page":false},on:{"update:active":function($event){_vm.requestLoading=$event}}}),_c('Person2',{ref:"person",attrs:{"person-id":_vm.person_id},on:{"onLoad":function (person) {
        this$1.person = person;
      },"switchToID":_vm.onSwitchToID,"onSave":_vm.onSave}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }